import React from 'react'
import About from './components/about/About'
import Footer from './components/footer/Footer'
import Portfolio from './components/portfolio/Portfolio'

const Projects= () => {
  return ( 
  <>
   <About />
   <Portfolio />
   <Footer />
  </>
  )
}

export default Projects